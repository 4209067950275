"use client";

import React, { useEffect, useState } from 'react';
import { createClient } from '@/utils/supabase-browser';
import {
  InputOTPWithSeparator,
} from "@/app/_components/ui/form/auth/InoutOtpWithSeperator"
import Notice from '@/app/_components/ui/notice';
import { Button } from '@/app/_components/ui/button';
import { signOut } from '@/actions/auth';
import {
  Fieldset,
  Field,
  Label,
  FieldGroup,
} from "@/app/_components/ui/fieldset";
import { Text } from "@/app/_components/ui/text";
import AccountLayout from './AccountLayout';
import { SubmitButton } from '../form/SubmitButton';


const AuthMFA = () => {
  const supabase = createClient();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerifyOtp = async () => {
    setLoading(true);
    setError("");

    try {
      const factors = await supabase.auth.mfa.listFactors();
      const totpFactor = factors.data?.totp[0];

      if (!totpFactor) {
        setError("No TOTP factor found");
        return;
      }

      const challenge = await supabase.auth.mfa.challenge({ factorId: totpFactor.id });
      if (challenge.error) {
        setError(challenge.error.message);
        return;
      }

      const { error: verifyError } = await supabase.auth.mfa.verify({
        factorId: totpFactor.id,
        challengeId: challenge.data.id,
        code: otp,
      });

      if (verifyError) {
        setError(verifyError.message);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error verifying OTP: ", err);
      setError("An unexpected error occurred.");
      setLoading(false);
    }
  };

  const handleSignout = async () => {
    setLoading(true);
    await signOut();
  };

  useEffect(() => {
    if(otp.length === 6) {
      handleVerifyOtp()
    }
  }, [otp])

  return (
    <AccountLayout>
      <form className="bg-white px-4 py-10 shadow sm:rounded-lg sm:px-10 mx-auto grid space-y-6" onSubmit={(e) => {
        e.preventDefault();
      }}>
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-center text-2xl font-bold text-gray-800">
            Sign In
              </h1>
          <Text className="text-center text-gray-500">
            Please enter the code from your authenticator app.
          </Text>
        </div>
        <Fieldset>
          <FieldGroup>
            <Field className="w-full flex justify-center mb-4">
              <InputOTPWithSeparator onOtpComplete={setOtp} />
            </Field>
          </FieldGroup>
        </Fieldset>
        <Field className="mt-4 flex items-center justify-between">
        <SubmitButton loading={loading} title="Verify code" color="orange" className="w-full" />
        </Field>
        {error && <Notice type="error" title="Error" message={error} />}
      </form>
      {/* Signout */}
      <div className="flex flex-col justify-center items-center mt-4">
        <Text className="!text-white">Trouble signing in?</Text>
        <div className="flex flex-row items-center justify-center">
          <Button onClick={handleSignout} type="button" plain className="!text-white underline">Retry Sign In</Button>
          <Text className="!text-white">or</Text>
          <Button href="mailto:sean.harte@nhs.net" type="button" plain className="!text-white underline">Contact Support</Button>
        </div>
       
      </div>
    </AccountLayout>
  );
};

export default AuthMFA;