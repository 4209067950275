

"use client";

import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/navigation";
import { createClient } from "@/utils/supabase-browser";
import ImageUpload from "@/app/_components/helpers/forms/ImageUpload";
import { ProfessionType } from "@/lib/types";
import { signup } from "@/actions/auth";
import { Button } from "@/app/_components/ui/button";
import { Input } from "@/app/_components/ui/input";
import DropdownField from "@/app/_components/ui/form/DropdownField";
import {
  Fieldset,
  Field,
  Label,
  FieldGroup,
} from "@/app/_components/ui/fieldset";
import { Text } from "@/app/_components/ui/text";
import PasswordStrengthProgress from "@/app/_components/account/PasswordStrengthProgress";

const Register = () => {
  const router = useRouter();
  const supabase = createClient();
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    profession: "",
    image: "",
    loadingRegister: false,
  });
  const [professions, setProfessions] = useState([] as ProfessionType[]);
  const [helperText, setHelperText] = useState({ error: false, text: "" });
  const [image, setImage] = useState<File | null>(null);

  const formRef = useRef({
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    profession: useRef<HTMLSelectElement>(null),
  });

  useEffect(() => {
    async function fetchProfessions() {
      const { data: professionData } = await supabase
        .from("users_professions")
        .select("id, name");

      if (!professionData) return;
      setProfessions(professionData as ProfessionType[]);
    }

    fetchProfessions();
  }, [supabase]);

  const handleSendNewUserNotification = async (name: string, email: string) => {
    try {
      const response = await fetch(`/api/send-new-user-notification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY}`,
        },
        body: JSON.stringify({
          name,
          email,
        }),
      });

      if (!response.ok) {
        console.error("Error sending new user notification:", response);
        throw new Error("Failed to send new user notification");
      }

    } catch (error) {
      console.error("Error sending new user notification:", error);
    }
  };

  const handleSignUp = async () => {
    setFormState({ ...formState, loadingRegister: true });
    let user, error, data;
    let fileName = "";

    const email = formState.email;
    const password = formState.password;
    const firstName = formState.firstName;
    const lastName = formState.lastName;
    const profession = formState.profession;

    if (!email || !password || !firstName || !lastName || !profession) {
      setHelperText({
        error: true,
        text: "All fields are required.",
      });
      setFormState({ ...formState, loadingRegister: false });
      return;
    }

    if (!email || !password || !firstName || !lastName || !profession) {
      setHelperText({
        error: true,
        text: "All fields are required.",
      });
      setFormState({ ...formState, loadingRegister: false });
      return;
    }
    const response = await signup({
      email: email,
      password: password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          profession: profession,
          role: 3,
        },
      },
    });

    user = response?.data.user;
    error = response?.error;

    if (error) {
      console.error("Error creating user: ", error);
      setHelperText({
        error: true,
        text: error.message,
      });
      setFormState({ ...formState, loadingRegister: false });
      return;
    }

    if (image) {
      const fileExt = image.name.split(".").pop();
      fileName = `${user?.id}.${fileExt}`;
      const { data: imageData, error: imageError } = await supabase.storage
        .from("avatars")
        .upload(fileName, image, {
          cacheControl: "3600",
          upsert: true,
        });

      if (imageError) {
        console.error("Error uploading image: ", imageError);
      }
    }

    if (user) {
      const { data: result, error: insertError } = await supabase
        .from("users")
        .insert([
          {
            id: user.id,
            first_name: firstName,
            last_name: lastName,
            email: email,
            profession: profession,
            avatar_url: fileName,
            role: 3,
          },
        ]);

      if (insertError) {
        setHelperText({
          error: true,
          text: insertError.message,
        });
        console.error("Error inserting user: ", insertError);
      } else {
        router.refresh();
        handleSendNewUserNotification(`${firstName} ${lastName}`, email);
      }

      setFormState({ ...formState, loadingRegister: false });
    }
  };

  return (
    <form
      className="mx-auto grid w-[350px] space-y-6"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-center text-2xl font-bold text-gray-800">
          Sign Up
        </h1>
        <Text className="text-center text-gray-500">Create your account.</Text>
      </div>
      <Fieldset>
        <FieldGroup>
          <div className="grid grid-cols-2 gap-4">
            <Field>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                ref={formRef.current.firstName}
                autoComplete="given-name"
                onChange={(e) =>
                  setFormState({ ...formState, firstName: e.target.value })
                }
                value={formState.firstName}
                required
              />
            </Field>
            <Field>
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                ref={formRef.current.lastName}
                autoComplete="family-name"
                onChange={(e) =>
                  setFormState({ ...formState, lastName: e.target.value })
                }
                value={formState.lastName}
                required
              />
            </Field>
          </div>
          <Field>
            <Label htmlFor="email">Email address</Label>
            <Input
              id="email"
              name="email"
              type="email"
              title="Please enter your email"
              ref={formRef.current.email}
              autoComplete="email"
              onChange={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
              value={formState.email}
              required
            />
          </Field>
          <Field>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              name="password"
              type="password"
              title="Please enter a password"
              ref={formRef.current.password}
              autoComplete="current-password"
              onChange={(e) =>
                setFormState({ ...formState, password: e.target.value })
              }
              value={formState.password}
              required
            />
            <PasswordStrengthProgress password={formState.password} />
          </Field>
          <Field>
            <DropdownField
              name="profession"
              label="Profession"
              onChange={(e) => {
                console.log(e);
                setFormState({ ...formState, profession: e as string });
              }}
              data={professions.map((profession) => ({
                id: profession.id,
                name: profession.name as string,
              }))}
            />
          </Field>
          <Field>
            <Label htmlFor="image" className="flex justify-between">
              Profile Image
              <span>
                <small className="text-gray-500">(Optional)</small>{" "}
              </span>
            </Label>
            <div className="mt-1 flex justify-center">
              <ImageUpload onImageSelect={(image) => setImage(image)} />
            </div>
          </Field>
        </FieldGroup>
      </Fieldset>
      {helperText.text && (
        <div
          className={`rounder-md my-2 border px-1 py-2 text-center text-sm ${
            helperText.error
              ? "border-red-300 bg-red-100 text-red-400"
              : "border-green-300 bg-green-100 text-green-500"
          }`}
        >
          {helperText.text}
        </div>
      )}
      <div>
        <Button
          type="submit"
          onClick={handleSignUp}
          color="orange"
          className="w-full"
          disabled={formState.loadingRegister}
        >
          {formState.loadingRegister ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="dark:text-whote mr-2 h-6 w-4 animate-spin fill-blue-600 text-gray-200"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Sign Up"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Register;