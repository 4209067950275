"use client";

import { useState, useEffect } from "react";
import { createClient } from "@/utils/supabase-browser";
import { useSearchParams } from "next/navigation";
import Login from "../_components/ui/form/auth/Login";
import Register from "../_components/ui/form/auth/Register";
import OtpForm from "../_components/ui/form/auth/OTP";
import AuthMFA from "../_components/account/AuthMFA";
import Image from "next/image";

const Auth = () => {
  const supabase = createClient();
  const [isLogin, setIsLogin] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [helperText, setHelperText] = useState({ error: false, text: "" });
  const searchParams = useSearchParams();
  const createAccount = searchParams.get("createAccount");

  const [readyToShow, setReadyToShow] = useState(false)
  const [showMFAScreen, setShowMFAScreen] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
        if (error) {
          throw error
        }

        console.log(data)

        if (data.nextLevel === 'aal2' && data.nextLevel !== data.currentLevel) {
          setShowMFAScreen(true)
        }
      } finally {
        setReadyToShow(true)
      }
    })()
  }, [])

  useEffect(() => {
    if (createAccount === "true") {
      setIsLogin(false);
      setIsOtp(false);
    }
  }, [createAccount]);

  return (
    <div className="flex min-h-full flex-col justify-center bg-indigo-400 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <Image
            className="mx-auto h-12 w-auto"
            src={"/images/logoIcon.png"}
            alt="Pathways"
            width={60}
            height={90}
          />
          <h1 className="text-center text-6xl font-bold text-white">
            Pathways
          </h1>
        </div>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-10 shadow sm:rounded-lg sm:px-10">
          {readyToShow && showMFAScreen ? <AuthMFA /> : isOtp ? <OtpForm /> : isLogin ? <Login /> : <Register />}

          {!isOtp && isLogin && (
            <div className="mx-auto mt-4 grid w-[350px] text-right">
              <a
                href="#"
                className="text-sm font-medium text-gray-600 underline hover:text-indigo-500"
                onClick={() => setIsOtp(true)}
              >
                Login via code
              </a>
            </div>
          )}
          {helperText.text && (
            <div
              className={`rounder-md my-2 border px-1 py-2 text-center text-sm ${
                helperText.error
                  ? "border-red-300 bg-red-100 text-red-400"
                  : "border-green-300 bg-green-100 text-green-500"
              }`}
            >
              {helperText.text}
            </div>
          )}
        </div>
        <div className="mt-8 text-center text-base text-white">
          <span className="">
            {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
          </span>

          <a
            href="#"
            className="font-bold text-white underline"
            onClick={(e) => {
              e.preventDefault();
              setIsLogin(!isLogin);
              setIsOtp(false);
            }}
          >
            {isLogin ? "Sign Up" : "Sign In"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Auth;
