"use client"

import * as React from "react"

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from "@/app/_components/ui/input-otp"

export function InputOTPWithSeparator({ onOtpComplete }: { onOtpComplete: (value: string) => void }) {
  const [value, setValue] = React.useState("")

   const handleChange = (newValue: string) => {
       setValue(newValue);
       if (newValue.length === 6) { // Assuming OTP length is 6
         onOtpComplete(newValue);
       }
     };

  return (
    <div className="space-y-2">
      <InputOTP
        maxLength={6}
        value={value}
        onChange={handleChange}
      >
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
        </InputOTPGroup>
        <InputOTPSeparator />
        <InputOTPGroup>
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
    </div>
  )
}
