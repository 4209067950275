"use client";

import Image from "next/image";

const AccountLayout = ({ children }: { children: React.ReactNode }) => {

  return (
    <div className="flex min-h-full flex-col justify-center bg-indigo-400 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <Image
            className="mx-auto h-12 w-auto"
            src={"/images/logoIcon.png"}
            alt="Pathways"
            width={60}
            height={90}
          />
          <h1 className="text-center text-6xl font-bold text-white">
            Pathways
          </h1>
        </div>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {children}
      </div>
    </div>
  );
};

export default AccountLayout;
