import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/account/AuthMFA.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Notification"] */ "/vercel/path0/app/_components/notifications/Notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/sidebar/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(auth)/Auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(auth)/supabase-listener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(auth)/supabase-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
